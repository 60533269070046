<template>
  <NotFoundStyled>
    {{ $t('messages.pageNotFound') }}
  </NotFoundStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const NotFoundStyled = styled('div')`
  ${flexCenter}
  height: 100%;
  font-size: 1.5rem;
`

export default {
  components: {
    NotFoundStyled,
  },
}
</script>
